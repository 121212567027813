import React from "react";
import "./Progress.scss";
import {illustration, techStack} from "../../portfolio";
import {Fade} from "react-reveal";
import Build from "../../assets/lottie/build";
import Skill from "../../assets/lottie/skill";
import DisplayLottie from "../../components/displayLottie/DisplayLottie";

export default function StackProgress() {
  return (
    <Fade bottom duration={1000} distance="20px">
      <div className="containr">
        <h1 className="title-text">Proficiency</h1>
        <div className="skills-container">
          <div className="skills-wrapper">
            <div className="skill-box">
              <span className="title">HTML</span>
              <div className="skill-bar">
                <span className="skill-per html">
                  <span className="tooltip">80%</span>
                </span>
              </div>
            </div>
            <div className="skill-box">
              <span className="title">CSS</span>
              <div className="skill-bar">
                <span className="skill-per css">
                  <span className="tooltip">75%</span>
                </span>
              </div>
            </div>
            <div className="skill-box">
              <span className="title">JavaScript</span>
              <div className="skill-bar">
                <span className="skill-per javascript">
                  <span className="tooltip">75%</span>
                </span>
              </div>
            </div>
            <div className="skill-box">
              <span className="title">React</span>
              <div className="skill-bar">
                <span className="skill-per reactjs">
                  <span className="tooltip">75%</span>
                </span>
              </div>
            </div>
            <div className="skill-box">
              <span className="title">Express.js</span>
              <div className="skill-bar">
                <span className="skill-per expressjs">
                  <span className="tooltip">75%</span>
                </span>
              </div>
            </div>
            <div className="skill-box">
              <span className="title">Database</span>
              <div className="skill-bar">
                <span className="skill-per database">
                  <span className="tooltip">75%</span>
                </span>
              </div>
            </div>
          </div>
          <div className="skills-image">
            {illustration.animated ? (
              <DisplayLottie animationData={Skill} />
            ) : (
              <img
                alt="Skills"
                src={require("../../assets/images/skill.svg")}
              />
            )}
          </div>
        </div>
      </div>
    </Fade>
  );
}
