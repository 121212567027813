import React, {useContext, useRef} from "react";
import "./WorkExperience.scss";
import ExperienceCard from "../../components/experienceCard/ExperienceCard";
import {workExperiences} from "../../portfolio";
import {Fade} from "react-reveal";
import StyleContext from "../../contexts/StyleContext";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {Slide} from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

export default function WorkExperience() {
  const {isDark} = useContext(StyleContext);
  const sliderRef = useRef(null);
  const scrollAmount = 300;

  if (workExperiences.display) {
    return (
      <div id="experience">
        <Fade bottom duration={1000} distance="20px">
          <div className="experience-container" id="workExperience">
            <div>
              <h1 className="experience-heading">Experience</h1>
              <div className="experience-cards-div" ref={sliderRef}>
                {workExperiences.experience.map((card, i) => {
                  return (
                    <ExperienceCard
                      key={i}
                      isDark={isDark}
                      cardInfo={{
                        company: card.company,
                        desc: card.desc,
                        date: card.date,
                        companylogo: card.companylogo,
                        role: card.role,
                        descBullets: card.descBullets
                      }}
                    />
                  );
                })}
              </div>
              <div className="buttons">
                <button
                  className="nav-btn"
                  onClick={() => {
                    const container = sliderRef.current;
                    container.scrollLeft -= scrollAmount;
                  }}
                >
                  <ChevronLeftIcon />
                </button>
                <button
                  className="nav-btn"
                  onClick={() => {
                    const container = sliderRef.current;
                    container.scrollLeft += scrollAmount;
                  }}
                >
                  <ChevronRightIcon />
                </button>
              </div>
            </div>
          </div>
        </Fade>
      </div>
    );
  }
  return null;
}
