import React, {useContext} from "react";
import {Fade} from "react-reveal";
import emoji from "react-easy-emoji";
import "./Greeting.scss";
import landingPerson from "../../assets/lottie/landingPerson";
import DisplayLottie from "../../components/displayLottie/DisplayLottie";
import SocialMedia from "../../components/socialMedia/SocialMedia";
import Button from "../../components/button/Button";
import {illustration, greeting} from "../../portfolio";
import StyleContext from "../../contexts/StyleContext";
import LinkedInPhoto from "../../assets/images/LinkedInPhoto.png";
import CoverLetter from "../../assets/pdf/CoverLetter.pdf";
import Resume from "../../assets/pdf/Resume.pdf";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";

export default function Greeting() {
  const {isDark} = useContext(StyleContext);
  if (!greeting.display) {
    return null;
  }
  return (
    <div className="greet-main" id="greeting">
      <div className="greeting-main">
        <div className="greeting-text-div">
          <div>
            <h1
              className={isDark ? "dark-mode greeting-text" : "greeting-text"}
            >
              {" "}
              {greeting.title} <span className="wave-emoji">{emoji("!!")}</span>
            </h1>
            <p
              className={
                isDark
                  ? "dark-mode greeting-text-p"
                  : "greeting-text-p subTitle"
              }
            >
              {greeting.subTitle}
            </p>
            <SocialMedia />
            <div className="button-greeting-div">
              <button
                type="button"
                class="main-button"
                data-bs-toggle="modal"
                data-bs-target="#ResumeModal"
              >
                Resume
              </button>

              <button
                type="button"
                class="main-button-1"
                data-bs-toggle="modal"
                data-bs-target="#CoverLetterModal"
              >
                Cover Letter
              </button>
            </div>

            <div
              class="modal fade"
              id="ResumeModal"
              tabindex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-xl">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="pdf-title-resume" id="exampleModalLabel">
                      Resume
                    </h5>
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body">
                    <iframe src="https://onedrive.live.com/embed?resid=B5C211CA34878DFA%2112413&authkey=!AOsIKxBQ9XNhJl0&em=2"></iframe>
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="main-button-close"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="modal fade"
              id="CoverLetterModal"
              tabindex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-xl">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="pdf-title-coverLetter" id="exampleModalLabel">
                      Cover Letter
                    </h5>
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body">
                    <iframe src="https://onedrive.live.com/embed?resid=B5C211CA34878DFA%2112417&authkey=!AFCGuwDjuroE5b0&em=2"></iframe>
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="main-button-close"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="greeting-image-div">
          <img
            alt="LinkedIn Photo"
            src={LinkedInPhoto}
            // style={{ width: '500px', height: '700px', marginLeft: '150px' }}
          ></img>
        </div>
      </div>
    </div>
  );
}
