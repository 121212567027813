import {useState, useEffect} from "react";
import React, {useContext} from "react";
import Headroom from "react-headroom";
import "./Header.scss";
import ToggleSwitch from "../ToggleSwitch/ToggleSwitch";
import StyleContext from "../../contexts/StyleContext";
import {
  greeting,
  educationInfo,
  workExperiences,
  skillsSection,
  bigProjects,
  blogSection,
  talkSection,
  achievementSection
} from "../../portfolio";

function Header() {
  const {isDark} = useContext(StyleContext);
  const viewExperience = workExperiences.display;
  const viewProjects = bigProjects.display;
  const viewSkills = skillsSection.display;
  const viewEducation = educationInfo.display;
  const viewAchievement = achievementSection.display;
  const viewBlog = blogSection.display;
  const viewTalks = talkSection.display;

  const [showHeader, setShowHeader] = useState(true);

  const handleHover = () => {
    // Clear the timeout and show the header on hover
    setShowHeader(true);
  };

  const handleLeave = () => {
    // Set a timeout to hide the header after 1 second when the cursor leaves the header
    setTimeout(() => {
      setShowHeader(false);
    }, 500);
  };

  return (
    <header
      className={`header ${showHeader ? "visible" : "hidden"}  `}
      onMouseEnter={handleHover}
      onMouseLeave={handleLeave}
    >
      <a href="/" className="logo">
        <span className="grey-color"> &lt;</span>
        <span className="logo-name">{greeting.username}</span>
        <span className="grey-color">/&gt;</span>
      </a>
      <input className="menu-btn" type="checkbox" id="menu-btn" />
      <label className="menu-icon" htmlFor="menu-btn" style={{color: "white"}}>
        <span className={isDark ? "navicon navicon-dark" : "navicon"}></span>
      </label>
      <ul className={isDark ? "dark-menu menu" : "menu"}>
        {viewSkills && (
          <li>
            <a href="#skills">Skills</a>
          </li>
        )}
        {viewEducation && (
          <li>
            <a href="#education">Education</a>
          </li>
        )}
        {viewExperience && (
          <li>
            <a href="#experience">Experience</a>
          </li>
        )}
        {viewProjects && (
          <li>
            <a href="#projects">Projects</a>
          </li>
        )}
        {/* {viewAchievement && (
            <li>
              <a href="#achievements">Achievements</a>
            </li>
          )}
          {viewBlog && (
            <li>
              <a href="#blogs">Blogs</a>
            </li>
          )}
          {viewTalks && (
            <li>
              <a href="#talks">Talks</a>
            </li>
          )} */}
        <li>
          <a href="#contact">Contact Me</a>
        </li>
        {/* <li>
            /eslint-disable-next-line jsx-a11y/anchor-is-valid /
            <a>
              <ToggleSwitch />
            </a>
          </li> */}
      </ul>
    </header>
  );
}
export default Header;
